import { Component, ElementRef, ViewChild, ViewChildren, Renderer2 } from '@angular/core';
import { DynamicUserService } from './dynamicUser.service';
import { Router } from '@angular/router';
import { ALERT_MSG_TIME_OUT } from 'app/constants';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { RoleScopeObjectMapping } from './RoleScopeObjectMapping.model';
import { combineLatest } from 'rxjs';
import { DOMAIN } from 'app/app.constants';
import { MessageService } from 'primeng/api';
import { CommonUtilsService } from 'app/shared/service/common-util.service';
@Component({
    selector: 'role-scope-mapping',
    templateUrl: './roleScopeMapping.component.html',
    styleUrls: ['./dynamicUser.component.css'],
    providers: [MessageService]
})
export class RoleScopeMappingComponent {
    isSuccess: any;
    operation: boolean;
    displayFailureMessage: any;
    isFailure: any;
    scopeIdList: any;
    scopeNameList: any;
    options: boolean;
    selectedValue: any;
    selectedComponentValue: string;
    selectedPermissionValue: string;
    roleScopeMappingList: Array<RoleScopeObjectMapping> = [];
    selectedScopeId: string;
    displaySuccessMessage: string;
    displayScopeList = false;
    scopeListForRole: any;
    displayScope: boolean;
    permissionVisible: boolean = true;
    selectedPermission: string;
    permissions: Array<any> = [];
    enableModal: boolean;
    resize: boolean;
    responsive: boolean;
    showScopeTab = false;
    showPermissionTab = false;
    showRoleTab = false;
    displayDelete: boolean;
    @ViewChild('checkBoxRow') checkBoxRow: ElementRef;
    @ViewChildren('items') items: ElementRef;
    noScopeMapped = false;
    selectedRoleName: any;
    selectedComponentId: any;
    roleRadioButtonSelected: boolean = false;
    roleComponentButtonSelected: boolean = false;
    isSelected: boolean = false;
    componentRolePermissionId: any;
    permissionDisabled: boolean = true;
    selectScopeForUpdate: any = [];
    permission: string;
    saveEnabled: boolean = true;
    isLoad: boolean = true;
    userIp: any;
    userId: any;
    windowsId: any;

    constructor(
        private renderer: Renderer2,
        private dynamicUserService: DynamicUserService,
        private _location: Location,
        private router: Router,
        private messageService: MessageService,
        private comUtil: CommonUtilsService
    ) {
        this.enableModal = true;
        this.resize = true;
        this.responsive = true;
        this.displayDelete = false;
        this.permissions = ['All', 'PORTFOLIO', 'SHADOW_PORTFOLIO'];
    }

    ngOnInit() {
        this.userId = this.comUtil.decrypt(localStorage.getItem('empid'));
        this.userIp = this.comUtil.decrypt(localStorage.getItem('userIpAddress'));
        this.windowsId = this.comUtil.decrypt(localStorage.getItem('userName'));
        this.renderer.removeClass(document.body, 'overflow-scroll');
        this.operation = true;
        this.getRoleNameList();
        this.getDisplayScope();
        this.hideLoader();
    }

    roleArray: any;
    getRoles() {
        this.dynamicUserService.getAllKeycloakRoles().subscribe(
            response => {
                let data = response;
                this.roleArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    getAllComponents() {
        combineLatest([
            this.dynamicUserService.getAllComponents(),
            this.dynamicUserService.getComponentRolePermissionMappingList(),
            this.dynamicUserService.getPermissionList()
        ]).subscribe(([allComponets, componentsPermissionMapping, permissionList]) => {
            this.componentArray = allComponets;
            this.componentRolePermissionArray = componentsPermissionMapping;
            this.permissionArray = permissionList;
            this.onComponetChange();
        });
    }

    scopeArray: any;
    getScopes() {
        this.dynamicUserService.getAllKeycloakScopes().subscribe(
            response => {
                let data = response;
                this.scopeArray = data;
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    componentArray: any;
    scopeAll: string;
    roleScopePermissionList: any;
    isRoleSelected: boolean = true;
    isDisabled: boolean = false;
    roleSelected: string;
    scopeListForSelectedRole: any;
    scopeListForRolePostRemoval: any;
    onRoleChangeHandler(event) {
        this.roleRadioButtonSelected = true;
        this.showPermissionTab = true;
        this.scopeListForRole = [];
        this.scopeListForSelectedRole = [];
        this.selectedRoleName = null;
        this.displayScopeList = true;
        if (this.selectedValue != null) {
            this.roleSelected = this.selectedValue.roleName;
            this.showScopeTab = true;
            this.dynamicUserService.getScopeSelectedRole(this.selectedValue.roleName).subscribe(response => {
                let data = response;
                if (data != null) {
                    if (data.scopeName != null) {
                        this.scopeListForSelectedRole = data.scopeName;
                    } else {
                        this.noScopeMapped = true;
                    }
                    this.selectedPermission = data.permissionName;
                } else {
                    this.selectedPermission = null;
                }
                this.noScopeMapped = this.scopeListForSelectedRole.length === 0;
            });
        } else {
            this.saveEnabled = true;
            this.displayScope = false;
            this.showScopeTab = false;
            this.selectedPermission = null;
            this.noScopeMapped = false;
        }
    }

    submitMapping(form: NgForm) {
        this.saveRoleScopePermissionMapping();
    }
    saveRoleScopeMapping() {
        let scopeArrayVar = this.scopeListForRole;
        const obj = {};
        obj['roleId'] = this.selectedValue;
        let roleName = this.selectedValue;
        scopeArrayVar.forEach(element => {
            if (element.status == 'ADD') {
                this.roleScopeMappingList.push(
                    new RoleScopeObjectMapping(this.selectedValue, element.scopeId, element.scopeName, element.status)
                );
                roleName = this.selectedValue;
            }
        });

        obj['roleScopeJSON'] = JSON.stringify(this.roleScopeMappingList);
        this.dynamicUserService.saveRoleScopeMapping(obj).subscribe(
            response => {
                if (this.noScopeMapped) {
                    let scopeListArray = [];
                    this.roleScopeMappingList.forEach(element => {
                        scopeListArray.push({ scopeId: element.scopeId, scopeName: element.scopeName, scopeChecked: false, status: null });
                    });
                    this.roleScopeListOriginalArray.push({
                        role: {
                            roleId: this.selectedValue,
                            roleName: roleName,
                            roleSelected: false
                        },
                        scopeList: scopeListArray
                    });
                }
                this.scopeListForRole = scopeArrayVar;
                this.showSuccessValidations(true, 'Successfully updated data.');
                if (this.scopeListForRole.length != 0) {
                    this.noScopeMapped = false;
                }
            },
            response => {
                this.showErrorValidations(true, response.error);
            }
        );
    }

    permissionNull: boolean = false;
    componentList: any;
    saveRoleScopePermissionMapping() {
        this.saveEnabled = true;
        const obj = {};
        this.componentList = [];

        if (this.selectedPermission != null) {
            obj['selectedRoleName'] = this.roleSelected;
            obj['selectedPermission'] = this.selectedPermission;
            obj['windowId'] = this.windowsId;
            obj['userId'] = this.userId;
            obj['ipAddress'] = this.userIp;
            this.scopeListForSelectedRole.forEach(element => {
                this.displayScopeArray.forEach(res => {
                    if (element == res.name) {
                        this.componentList.push(res.component);
                    }
                });
            });
            let myComponents = new Set(this.componentList);
            this.componentList = [...myComponents];
            if (this.scopeListForSelectedRole.length != 0) {
                obj['scopeForSelectedRole'] = JSON.stringify(this.scopeListForSelectedRole);
                obj['componentList'] = JSON.stringify(this.componentList);
            } else {
                obj['scopeForSelectedRole'] = null;
                obj['componentList'] = null;
            }

            this.dynamicUserService.saveRoleScopePermissionMapping(obj).subscribe(
                response => {
                    this.messageService.add({ severity: 'success', summary: 'Successfully updated data.' });
                },
                response => {
                    this.saveEnabled = false;
                    this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
                }
            );
        } else {
            this.permissionNull = true;
        }
    }

    saveComponentRolePermissionMapping() {
        const obj = {};
        obj['selectedComponentName'] = this.selectedComponentValue;
        obj['selectedRoleName'] = this.selectedRoleName;
        obj['selectedComponentId'] = this.selectedComponentId;
        obj['permissionJSON'] = JSON.stringify(this.permissionArray);
        obj['selectedPermission'] = this.isSelected;
        obj['componentRolePermissionId'] = this.componentRolePermissionId;

        this.dynamicUserService.saveComponentRolePermissionMapping(obj).subscribe(
            response => {
                this.messageService.add({ severity: 'success', summary: 'Successfully updated data.' });
            },
            response => {
                this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
            }
        );
    }

    getCheckList({ value: { value } }) {
        if (this.selectedPermission == null) {
            this.permissionNull = true;
            this.saveEnabled = true;
        } else {
            this.permissionNull = false;
            this.saveEnabled = false;
        }
        this.permissionArray.forEach(element => {
            const permissionChecked = element.name == value;
            element.status = permissionChecked ? 'ADD' : 'REMOVE';
            element.permissionChecked = permissionChecked;
        });
    }

    componentSelected: any;
    roleListArray: any;
    permissionListForSelectedComponent: [];
    onComponetChange({ index = 0 } = {}) {
        this.roleComponentButtonSelected = true;
        let selectedRole = this.selectedRoleName;
        this.permissionArray.forEach(element1 => {
            element1.permissionChecked = false;
            element1.status = null;
        });
        this.componentSelected = this.componentArray[index].name;
        this.selectedComponentId = this.componentArray[index].id;
        this.permissionListForSelectedComponent = [];
        this.componentRolePermissionArray.forEach(element => {
            if (element.componentName == this.componentSelected && element.roleName == selectedRole) {
                this.permissionArray.forEach(element1 => {
                    if (element.permissionName == element1.name) {
                        element1.permissionChecked = true;
                    }
                });
            }
        });
        if (this.roleComponentButtonSelected) {
            this.showRoleTab = true;
        }
    }

    getRoleNameList() {
        this.dynamicUserService.getRoleNameList().subscribe(response => {
            let data = response;
            this.roleListArray = data;
        });
    }

    displayScopeArray: any;
    getDisplayScope() {
        this.dynamicUserService.getDisplayScope().subscribe(response => {
            let data = response;
            this.displayScopeArray = data;
        });
    }

    displayScopeRemoval: any;
    displayScopee: any;
    scope: any = [];
    addNewScopee() {
        this.selectScopeForUpdate = [];
        this.scope = [];
        this.displayScopeArray.forEach(element => {
            this.scope.push(element.name);
        });
        this.displayScopeRemoval = [];
        if (this.scopeListForSelectedRole == null) {
            this.displayScopee = this.scope;
        } else {
            this.scope.forEach(element => {
                let presentScope = false;
                this.scopeListForSelectedRole.forEach(value => {
                    if (value == element) {
                        presentScope = true;
                    }
                });
                if (!presentScope) {
                    this.displayScopeRemoval.push(element);
                }
            });
        }
        this.scope = this.displayScopeRemoval;
        this.displayScope = true;
    }

    keyCloakScopeList: any;
    keyCloakScopes: any;
    keyCloakScopesPostRemoval: any;

    addNewScope() {
        this.dynamicUserService.getKeyCloakScopeList().subscribe(response => {
            let data = response;
            this.keyCloakScopes = data;
            this.keyCloakScopesPostRemoval = [];
            if (this.scopeListForRole == null) {
                this.keyCloakScopeList = this.keyCloakScopes;
            } else {
                this.keyCloakScopes.forEach((element, index) => {
                    let presentScope = false;
                    this.scopeListForRole.forEach(value => {
                        if (value.scopeId == element.scopeId) {
                            presentScope = true;
                        }
                    });
                    if (!presentScope) {
                        this.keyCloakScopesPostRemoval.push(element);
                    }
                });
            }
            this.keyCloakScopeList = this.keyCloakScopesPostRemoval;
        });
        this.displayDelete = false;
        this.displayScope = true;
    }

    updateScopee() {
        this.selectScopeForUpdate.forEach(element => {
            this.scopeListForSelectedRole.push(element);
        });
        this.displayScope = false;
        this.noScopeMapped = this.scopeListForSelectedRole.length === 0;

        const obj = {};
        this.scopeUpDe = 'ADD';
        obj['status'] = this.scopeUpDe;
        obj['roleName'] = this.selectedRoleName;
        obj['updatingScope'] = this.selectScopeForUpdate;
        this.hideSession = true;
        this.saveEnabled = false;
        document.getElementById('updateScope').classList.remove('sessionModal');
    }
    discardUpdateScope() {
        this.displayScope = false;
        document.getElementById('updateScope').classList.remove('sessionModal');
    }
    roleScopeList: any = [];
    updateScope() {
        this.roleScopeMappingList = [];
        const obbj = {};
        this.keyCloakScopeList.forEach(element => {
            if (element.scopeChecked.length == 1) {
                element.scopeChecked = false;
                element.status = 'ADD';
                this.scopeListForRole.push(element);
                this.roleScopeList.push(element.scopeName);
                this.roleScopeMappingList.push(
                    // Point
                    new RoleScopeObjectMapping(this.selectedValue.roleName, element.scopeId, element.scopeName, element.status)
                );
            }
        });

        this.displayScope = false;
        const obj = {};
        this.roleArray.forEach(element => {
            if (element.name == this.selectedValue.roleName) {
                this.roleId = element.id;
            }
        });
        obj['roleName'] = this.selectedValue.roleName;
        obj['roleId'] = this.roleId;
        obj['roleScopeJSON'] = JSON.stringify(this.roleScopeMappingList);
        obbj['roleName'] = this.selectedValue.roleName;
        obbj['roleScopeList'] = this.roleScopeList;
        this.dynamicUserService.saveRoleScopeMapping(obj).subscribe(
            response => {
                this.messageService.add({ severity: 'success', summary: 'Successfully updated data.' });
            },
            response => {
                this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
            }
        );
    }

    selectScopeForDelete: any;
    openDeleteDataModal(scopeObject) {
        this.displayScope = false;
        this.displayDelete = true;
        this.selectScopeForDelete = scopeObject;
    }

    scopeUpDe: string;
    deleteeScopeData() {
        this.scopeListForSelectedRole.forEach((element, index) => {
            if (element == this.selectScopeForDelete) {
                this.scopeListForSelectedRole.splice(index, 1);
                this.scopeUpDe = 'DELETE';
            }
        });
        this.displayDelete = false;
        document.getElementById('deleteScope').classList.remove('sessionModal');
        this.saveEnabled = false;
        const obj = {};
        obj['status'] = this.scopeUpDe;
        obj['roleName'] = this.roleSelected;
        obj['updatingScope'] = this.selectScopeForDelete;
        this.hideSession = true;
    }
    roleId: any;
    hideSession: boolean = false;
    deleteScopeData() {
        if (this.roleScopeMappingList[0].status == 'ADD') {
            this.scopeListForRole.forEach((value, index) => {
                if (value.scopeId == this.roleScopeMappingList[0].scopeId) {
                    this.scopeListForRole.splice(index, 1);
                }
            });
            this.displayDelete = false;
            this.showSuccessValidations(true, 'Successfully updated data.');
        } else {
            const obj = {};
            this.roleArray.forEach(element => {
                if (element.name == this.selectedValue.roleName) {
                    this.roleId = element.id;
                }
            });
            obj['roleName'] = this.selectedValue.roleName;
            obj['roleId'] = this.roleId;
            obj['roleScopeJSON'] = JSON.stringify(this.roleScopeMappingList);
            this.dynamicUserService.saveRoleScopeMapping(obj).subscribe(
                response => {
                    this.scopeListForRole.forEach((value, index) => {
                        if (value.scopeId == this.roleScopeMappingList[0].scopeId) {
                            this.scopeListForRole.splice(index, 1);
                        }
                    });
                    this.displayDelete = false;
                    this.messageService.add({ severity: 'success', summary: 'Successfully updated data.' });
                },
                response => {
                    this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
                }
            );
        }
    }

    discard() {
        this.displayDelete = false;
        document.getElementById('deleteScope').classList.remove('sessionModal');
        this.hideSession = true;
    }

    roleScopeListArray: any;
    roleScopeListOriginalArray: any;
    getRoleScopeObjectMappingList() {
        this.dynamicUserService.getRoleScopeObjectMappingList().subscribe(response => {
            let data = response;
            this.roleScopeListArray = data;
            this.roleScopeListOriginalArray = data;
        });
    }
    componentRolePermissionArray: any;
    permissionArray: any;
    ngAfterViewInit() {
    }

    roleListForSelectedScope: any;
    getRoleListOnScopeSelection(_selectedScpoeId: string) {
        this.dynamicUserService.getListOfRolesOnScopeSelection(_selectedScpoeId).subscribe(
            response => {
                let data = response;
                this.roleListForSelectedScope = data;
                let roleArrayVar = this.roleArray;
                roleArrayVar.forEach(element => {
                    (<HTMLInputElement>document.getElementById(element.id)).checked = false;
                });

                if (this.roleListForSelectedScope != null) {
                    this.roleListForSelectedScope.forEach(element => {
                        (<HTMLInputElement>document.getElementById(element.id)).checked = true;
                    });
                }
            },
            response => {
                this.messageService.add({ severity: 'error', summary: 'Something went wrong.' });
            }
        );
    }

    showErrorValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isSuccess = false;
        this.isFailure = showMessage;
        document.documentElement.scrollTop = 0;
        this.displayFailureMessage = displayValidationMessage;
        setTimeout(() => {
            this.isFailure = false;
            this.displayFailureMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }

    showSuccessValidations(showMessage: boolean, displayValidationMessage: string) {
        this.isFailure = false;
        this.isSuccess = showMessage;
        document.documentElement.scrollTop = 0;
        this.displaySuccessMessage = displayValidationMessage;
        setTimeout(() => {
            this.isSuccess = false;
            this.displaySuccessMessage = '';
        }, ALERT_MSG_TIME_OUT);
    }

    hideLoader() {
        setTimeout(() => {
            this.isLoad = false;
        }, 3000);
    }

    backFunction() {
        this.router.navigate(['']);
    }

    ccprimebreadcrumbList = [
        { label: `Dashboard`, routerLink: [''] },
        { label: `Role Scope Mapping`, routerLink: ['/administrationHome/roleScopeMappingList/roleScopeMapping'] }
    ];

    homeiconccprimebreadcrumb = { icon: 'pi pi-home' };

    itemClicked(event) {
        if (event.item.icon == 'pi pi-home') {
            sessionStorage.removeItem('urlRedirectedTo');
            sessionStorage.setItem('dashboard', 'true');
            window.location.href = `${DOMAIN}#/nexera/dashboard/home`;
        }
    }
}
