<div class="layout-content layout-content-noscroll">
    <p-toast class="custom-toast"></p-toast>
    <div>
        <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="isSuccess == true">
            <i class="pi pi-check" style="font-size: 1em" aria-hidden="true"></i> {{ displaySuccessMessage }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isSuccess = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div>
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="isFailure == true">
            <i class="pi pi-pi-exclamation-triangle" style="font-size: 1em" aria-hidden="true"></i> {{ displayFailureMessage }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="isFailure = false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="p-formgrid p-grid">
        <div class="p-col-12 p-pl-0 p-pr-0">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pl-0 p-pr-0">
                    <div class="">
                        <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb"
                            (onItemClick)="itemClicked($event)">
                        </p-breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="p-formgrid p-grid">
        <div class=" p-col-12 p-pl-0 p-pr-0">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 p-pl-0 p-pr-0">
                    <div class="p-field p-mb-0">
                        <div class="p-grid p-mt-0 p-ai-end">
                            <div class="p-col-4 p-pl-0">
                                <div class="p-field p-fluid p-mb-0">
                                    <label>Roles</label>
                                    <div>
                                        <p-dropdown [options]="roleListArray" [(ngModel)]="selectedValue"
                                            optionLabel="roleName" [filter]="true" filterBy="roleName" [showClear]="true"
                                            placeholder="Select" (onChange)="onRoleChangeHandler($event)">
                                            <ng-template pTemplate="selectedItem">
                                                <div class="role-item role-item-value">
                                                    <div>{{ selectedValue?.roleName }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-role pTemplate="item">
                                                <div class="role-item">
                                                    <div>{{ role.roleName }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-4">
                                <div class="p-field p-fluid p-mb-0">
                                    <label>Permisson</label>
                                    <div>
                                        <p-dropdown [options]="permissions" [(ngModel)]="selectedPermission"
                                            placeholder="Select" (onChange)="getCheckList($event)">
                                            <ng-template pTemplate="selectedItem">
                                                <div class="permisson-item permisson-item-value">
                                                    <div>{{ selectedPermission }}</div>
                                                </div>
                                            </ng-template>
                                            <ng-template let-permisson pTemplate="item">
                                                <div class="permisson-item">
                                                    <div>{{ permisson }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                    <div *ngIf="permissionNull" style="font-size: 11px; position: absolute;"
                                        class="p-error">
                                        Permission is Required.
                                    </div>
                                </div>
                            </div>
                            <div class="p-col-4 p-text-right p-pr-0">
                                <div *ngIf="showScopeTab">
                                    <div>
                                        <button pButton type="button" label="Add New Scope"
                                            class="p-button-secondary p-mr-0" (click)="addNewScopee()"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section class="slide-2 p-mt-2" id="slide-2">
                            <form id="createRoleScopeMappingForm" name="createRoleScopeMappingForm" role="form"
                                (ngSubmit)="submitMapping(createRoleScopeMappingForm)"
                                #createRoleScopeMappingForm="ngForm" autocomplete="off">
                                <div class="create-client">
                                    <div class="main-view">
                                        <p-table [value]="scopeListForSelectedRole" [ngClass]="{'hide': noScopeMapped}" [loading]="loading" [autoLayout]="true" id="role_table" *ngIf="showScopeTab" [resizableColumns]="true" styleClass="p-datatable-gridlines custom-scroll scroll-shadow-table" [scrollable]="true" scrollDirection="both" scrollHeight="53vh">
                                            <ng-template pTemplate="header"> 
                                                <tr>
                                                    <th id="dummyScope">Scope</th>
                                                    <th id="dummy"></th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                                <tr>
                                                    <td>{{ rowData }}</td>
                                                    <td>
                                                        <button pButton type="button" icon="pi pi-trash" id="delete_icon"
                                                            class="p-button-strict p-button-info p-button-sm"
                                                            (click)="openDeleteDataModal(rowData)"></button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        &nbsp;
                                        <div *ngIf="noScopeMapped" class="p-error invalid-feedback">
                                            No Scope Mapped to Selected Role
                                        </div>
                                        <p-dialog [(visible)]="displayScope" [style]="{ width: '400px' }"
                                            [enableModal]="enableModal" id="updateScope" [enableResize]="resize"
                                            [isResponsive]="responsive" [baseZIndex]="10000" class="selectScopeModel"
                                            [ngClass]="{'sessionModal' : !displayScope}">
                                            <ng-template pTemplate="header">
                                                Select Scope
                                            </ng-template>
                                            <div>
                                                <div *ngFor="let scopee of scope">
                                                    <div class="p-field-checkbox">
                                                        <p-checkbox name="{{ scopee }}" inputId="{{ scopee }}"
                                                            class="p-mr-2" value="{{ scopee }}"
                                                            [(ngModel)]="selectScopeForUpdate">
                                                        </p-checkbox>
                                                        <label class="p-ml-0" for={{scopee}}>{{ scopee }}</label>
                                                    </div>
                                                </div>
                                                <div *ngIf="scope.length==0">
                                                    <p>No Scopes Available.</p>
                                                </div>
                                            </div>
                                            <ng-template pTemplate="footer">
                                                <button type="button" pButton (click)="discardUpdateScope()"
                                                    label="Cancel" class="p-button-brand2"></button>
                                                <button type="button" pButton class="p-button-primary p-mr-0" [disabled]="scope.length==0 || selectScopeForUpdate.length==0"
                                                    label="Update" (click)="updateScopee()"></button>
                                            </ng-template>

                                        </p-dialog>



                                        <p-dialog header="Delete Scope" [(visible)]="displayDelete"
                                            [style]="{ width: '40vw' }" [baseZIndex]="10000" id="deleteScope"
                                            [resizable]="false" [ngClass]="{'sessionModal' : !displayDelete}">
                                            <div class="p-grid">
                                                <div class="p-col-12 p-sm-12 p-pt-1 p-px-0" [(ngModel)]="selectScopeForDelete">
                                                    <p>Are you sure you want to delete Scope {{ selectScopeForDelete }}?
                                                    </p>
                                                </div>
                                            </div>
                                            <p-p-footer>
                                                <div class="p-grid">
                                                    <div class="p-col-12 p-sm-12 p-d-flex p-jc-end p-pt-1">
                                                        <button pButton type="button" label="No" (click)="discard()"
                                                            class="p-button-secondary p-mr-3"></button>
                                                        <button pButton type="button" label="Yes"
                                                            class="p-button-primary"
                                                            (click)="deleteeScopeData()"></button>
                                                    </div>
                                                </div>
                                            </p-p-footer>
                                        </p-dialog>


                                    </div>
                                </div>
                            </form>
                        </section>
                        <!-- <div class="no_data_div" *ngIf="isLoad">
                            <div class="app-loading" id="loader">
                                <div class="loadbg"></div>
                                <div class="bdload">
                                    <div class="spinner">
                                        <div class="rect1"></div>
                                        <div class="rect2"></div>
                                        <div class="rect3"></div>
                                        <div class="rect4"></div>
                                        <div class="rect5"></div>
                                        <span>Customer Connect...</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-text-left p-pl-0">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" (click)="backFunction()" type="button" label="Back">
            </button>
        </div>
    </div>
    <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-text-right p-pr-0">
        <div class="btnwrap">
            <button class="p-button-primary" *ngIf="operation" [disabled]="saveEnabled"
                (click)="saveRoleScopePermissionMapping()" pButton label="Save"></button>
        </div>
    </div>
</div>