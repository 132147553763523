<div class="layout-content">
    
  <div class="p-formgrid p-grid">
      <div class=" p-col-12 p-pl-0 p-pr-0">
          <div class="ui-fluid p-formgrid p-grid">
              <div class="p-col-12 p-sm-12 p-pl-0 p-text-left">
                  <div class="p-fluid   p-field   p-mb-0 ">
                      <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb" (onItemClick)="itemClicked($event)">
                      </p-breadcrumb>
                  </div>
              </div>
          </div>
      </div>
  </div>
  
      <div class="p-formgrid p-grid formFieldContainer">
          <div class=" p-col-12 p-pl-0 p-pr-0">
              <div class="ui-fluid p-formgrid p-grid">
                  <div class="p-col-12 p-sm-12 p-pl-0 p-pr-0 left">
                      <div class="p-field">
                          <form
                          id="createUserForm"
                          name="createUserForm"
                          role="form"
                          (ngSubmit)="saveUser(createUserForm)"
                          #createUserForm="ngForm"
                          autocomplete="off">
                          <div class="p-mt-0">
                              <div>
                                  <div class="p-grid p-mt-0">
                                      <div class="p-col-12 p-md-12 p-pl-0">
                                          <p-panel header="  {{ userType }} User Details " [toggleable]="true" [style]="{ 'margin-bottom': '20px' }">
                                              <div class="p-grid p-mt-0">
                                                  <div class="p-col-12 p-md-12">
                                                      <div>
                                                          <div class="p-grid p-mt-0">
                                                              <div class="p-col-12 p-md-3 p-d-flex p-flex-column">
                                                                  <label class="first">Username</label>
                                                                  <input
                                                                      readonly
                                                                      type="text"
                                                                      [(ngModel)]="keyCloakUserName"
                                                                      name="keyCloakUserName"
                                                                      class="form-control form-control-sm"
                                                                      pattern="^[a-zA-Z0-9'_.-\s]+"
                                                                      minlength="1"
                                                                      maxlength="100"
                                                                  />
                                                              </div>
          
                                                              <div class="p-col-12 p-md-3 p-d-flex p-flex-column">
                                                                  <label class="first">First Name</label>
                                                                  <input
                                                                      readonly
                                                                      type="text"
                                                                      [(ngModel)]="keyCloakFirstName"
                                                                      name="keyCloakFirstName"
                                                                      class="form-control form-control-sm"
                                                                      pattern="^[a-zA-Z0-9'_.-\s]+"
                                                                      minlength="1"
                                                                      maxlength="100"
                                                                  />
                                                              </div>
          
                                                              <div class="p-col-12 p-md-3 p-d-flex p-flex-column">
                                                                  <label class="first">Last Name</label>
                                                                  <input
                                                                      readonly
                                                                      type="text"
                                                                      [(ngModel)]="keyCloakLastName"
                                                                      name="keyCloakLastName"
                                                                      class="form-control form-control-sm"
                                                                      pattern="^[a-zA-Z0-9'_.-\s]+"
                                                                      minlength="1"
                                                                      maxlength="100"
                                                                  />
                                                              </div>
          
                                                              <div class="p-col-12 p-md-3 p-d-flex p-flex-column">
                                                                  <label class="first">Email</label>
                                                                  <input
                                                                      readonly
                                                                      type="text"
                                                                      [(ngModel)]="keyCloakEmail"
                                                                      name="keyCloakEmail"
                                                                      class="form-control form-control-sm"
                                                                      pattern="^[a-zA-Z0-9'_.-\s]+"
                                                                      minlength="1"
                                                                      maxlength="100"
                                                                  />
                                                              </div>
          
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </p-panel>
                                      </div>
                                  </div>
          
                                  <div class="p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6  left p-pl-0">
                                        <p-panel header="User Roles" [toggleable]="true" [style]="{ 'margin-bottom': '20px' }">
                                            <div class="p-grid p-m-0 role_scope">
                                                <div class="p-col-12 p-md-3" *ngIf="!noScopeMapped">
                                                    <label [(ngModel)] = "roleName">{{ roleName }}</label>
                                                </div>
                                                <div *ngIf="noScopeMapped" class="p-error">
                                                  No Role Mapped to Selected User
                                                </div>
                                            </div>
                                        </p-panel>
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-xl-6  right">
                                        <p-panel header="Permission" [toggleable]="true" [style]="{ 'margin-bottom': '20px' }">
                                            <div class="p-grid p-m-0 role_scope">
                                              <div class="p-col-12 p-md-3" *ngIf="!noScopeMapped">
                                                <label [(ngModel)] = "permissionName">{{ permissionName }}</label>
                                              </div>
                                              <div *ngIf="noScopeMapped" class="p-error">
                                                No Permission Mapped to Selected Role
                                              </div>
                                            </div>
                                          </p-panel>
                                    </div>
                                  </div>
                                  
                                <div class="p-formgrid p-grid">
                                    <div class="p-col-12 p-sm-12 p-pl-0">
                                        <p-panel header="Scope for selected Role" [toggleable]="true" [style]="{ 'margin-bottom': '20px' }">
                                            <div class="p-grid p-m-0 role_scope">
                                                <div *ngFor="let scope of scopeList" class="p-col-12 p-md-3">
                                                    <label name="scope" [value]="scope">{{ scope }}</label>
                                                </div>
                                                <div *ngIf="noScopeMapped" class="p-error">
                                                    No Scope Mapped to Selected Role
                                                </div>
                                            </div>
                                        </p-panel>
                                    </div>
                                </div>
                                  
                                
                              </div>
                          </div>
                      </form>
                      
                      </div>
                  </div>
              </div>
          </div>
      </div>
</div>
<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-text-left">
        <div class="btnwrap">
            <button pButton class="p-button-brand2 p-button" (click)="backFunction()" type="button" label="Back">
            </button>
        </div>
    </div>
</div>
<!-- <div class="no_data_div" *ngIf="isLoad">
    <div class="app-loading" id="loader" >
        <div class="loadbg"></div>
        <div class="bdload">
          <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
            <span>Loading...</span>
          </div>
        </div>
    </div>
</div> -->