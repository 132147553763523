import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { DATA_1, DATA_2 } from 'app/app.constants';

@Injectable()
export class EncryptDecryptInterceptor implements HttpInterceptor {
    plainText: any;
    encryptedText: any;
    conversionEncryptOutput: any;
    conversionDecryptOutput: any;
    data_1: any; // iv
    data_2: any; // key
    encryptData: any;
    par = new Map();

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.data_1 = CryptoJS.enc.Latin1.parse(DATA_1);
        this.data_2 = CryptoJS.enc.Latin1.parse(DATA_2);
    }

    paramsObject: any;
    valParamObject: any;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method.toUpperCase() === 'GET') {
            this.paramsObject = [];
            this.paramsObject = request.params.keys();
            let paramS = new HttpParams();
            this.valParamObject = [];
            this.paramsObject.forEach(element => {
                let a = request.params.get(element);
                this.plainText = a.toString();
                let encrypted = CryptoJS.AES.encrypt(this.plainText, this.data_2, {
                    iv: this.data_1,
                    padding: CryptoJS.pad.Pkcs7,
                    mode: CryptoJS.mode.CBC
                });
                this.conversionEncryptOutput = encrypted.toString();
                paramS = paramS.set(element, this.conversionEncryptOutput);
            });

            if (this.paramsObject.length != 0) {
                return next
                    .handle(
                        request.clone({
                            params: paramS
                        })
                    )
                    .pipe(
                        map(evt => {
                            if (evt instanceof HttpResponse) {
                                let body = this.modifyBody(evt.body);
                                body = JSON.parse(body);
                                evt = evt.clone({ body: body });
                            }
                            return evt;
                        })
                    );
            } else {
                return next.handle(request).pipe(
                    map(evt => {
                        if (evt instanceof HttpResponse) {
                            let body = this.modifyBody(evt.body);
                            body = JSON.parse(body);
                            evt = evt.clone({ body: body });
                        }
                        return evt;
                    })
                );
            }
        } else if (request.method.toUpperCase() === 'POST') {
            this.plainText = JSON.stringify(request.body);
            let padMsg = this.padString(this.plainText.toString());
            let encrypted = CryptoJS.AES.encrypt(padMsg, this.data_2, { iv: this.data_1, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
            this.conversionEncryptOutput = encrypted.toString();
            const obj = {};
            obj['data'] = this.conversionEncryptOutput;
            return next
                .handle(
                    request.clone({
                        body: obj
                    })
                )
                .pipe(
                    map(evt => {
                        if (evt instanceof HttpResponse) {
                            let body = this.modifyBody(evt.body);
                            body = JSON.parse(body);
                            evt = evt.clone({ body: body });
                        }
                        return evt;
                    })
                );
        }
    }

    private padString(source: string) {
        let paddingChar = ' ';
        let size = 16;
        let x = source.length % size;
        let padLength = size - x;
        for (let i = 0; i < padLength; i++) source += paddingChar;
        return source;
    }
    private modifyBody(body) {
        this.encryptData = body;
        let padMsgRes = this.encryptData.data.toString();
        let decryptDataPlain = CryptoJS.AES.decrypt(padMsgRes, this.data_2, {
            iv: this.data_1,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        let decryptData = decryptDataPlain.toString(CryptoJS.enc.Utf8);
        return decryptData;
    }
}
