// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

import { environment } from 'src/environments/environment.prod';
// import { environment } from 'src/environments/environment.qa';

export const VERSION = process.env.VERSION;
export const DEBUG_INFO_ENABLED = Boolean(process.env.DEBUG_INFO_ENABLED);
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
export let KIBANA_UI_SERVICE_API_URL = environment.KIBANA_UI_SERVICE_API_URL;
export let KEY_CLOAK_REALM_NAME = environment.KEY_CLOAK_REALM_NAME;
export let KEY_CLOAK_URL = environment.KEY_CLOAK_URL;
export let KEY_CLOAK_CLIENT_ID = environment.KEY_CLOAK_CLIENT_ID;
export let USER_MANAGEMENT_SERVER_API_URL = environment.USER_MANAGEMENT_SERVER_API_URL;
export let profile = environment.profile;
export let IMAGE_PATH = environment.IMAGE_PATH;
export let REDIRECT_URI = process.env.REDIRECT_URI;
export let AGGREGATOR_MANAGEMENT_SERVER_API_URL = 'http://localhost:8082/api';
export const DOMAIN = environment.DOMAIN;
export const DATA_1 = environment.DATA_1;
export const DATA_2 = environment.DATA_2;
