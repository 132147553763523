<style>
:host ::ng-deep .p-grid{
  margin: 0;
}
</style>
<div class="layout-content">
    <div class="p-formgrid p-grid">
        <div class=" p-col-12 p-pl-0">
            <div class="ui-fluid p-formgrid p-grid">
                <p-breadcrumb [model]="ccprimebreadcrumbList" [home]="homeiconccprimebreadcrumb" (onItemClick)="itemClicked($event)">
                </p-breadcrumb>
            </div>
        </div>
    </div>
    <div class="p-formgrid p-grid formFieldContainer">
        <div class="row p-col-12 p-px-0">
            <div class="ui-fluid p-formgrid p-grid pt-22">
                <div class="p-col-12 p-md-4 p-lg-3 dash-card cursor-pointer" routerLink="/administrationHome/userCreationList">
                    <p-card class="box box1">
                        <div class="p-grid p-d-flex p-ai-center">
                            <span class="boxicon p-d-flex p-jc-between p-ai-center">
                                <svg class="illustrationLight" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="https://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="4" fill="#EBF3FE"/>
                                    <path d="M27.8327 32.5V30.1667C27.8327 28.929 27.341 27.742 26.4658 26.8668C25.5907 25.9917 24.4037 25.5 23.166 25.5H13.8327C12.595 25.5 11.408 25.9917 10.5329 26.8668C9.65768 27.742 9.16602 28.929 9.16602 30.1667V32.5" stroke="#4585ED" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.4997 20.8333C21.077 20.8333 23.1663 18.744 23.1663 16.1667C23.1663 13.5893 21.077 11.5 18.4997 11.5C15.9223 11.5 13.833 13.5893 13.833 16.1667C13.833 18.744 15.9223 20.8333 18.4997 20.8333Z" stroke="#4585ED" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M34.833 32.4999V30.1666C34.8322 29.1326 34.4881 28.1282 33.8546 27.311C33.2211 26.4938 32.3342 25.9101 31.333 25.6516" stroke="#4585ED" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M26.666 11.6516C27.6698 11.9086 28.5596 12.4924 29.1949 13.311C29.8303 14.1295 30.1752 15.1362 30.1752 16.1724C30.1752 17.2086 29.8303 18.2154 29.1949 19.0339C28.5596 19.8525 27.6698 20.4363 26.666 20.6933" stroke="#4585ED" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>  
                                <svg class="illustrationDark usermanagementSVG" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="https://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="4" fill="#333333"/>
                                    <path d="M27.8327 32.5V30.1667C27.8327 28.929 27.341 27.742 26.4658 26.8668C25.5907 25.9917 24.4037 25.5 23.166 25.5H13.8327C12.595 25.5 11.408 25.9917 10.5329 26.8668C9.65768 27.742 9.16602 28.929 9.16602 30.1667V32.5" stroke="#4F8FF7" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.4997 20.8333C21.077 20.8333 23.1663 18.744 23.1663 16.1667C23.1663 13.5893 21.077 11.5 18.4997 11.5C15.9223 11.5 13.833 13.5893 13.833 16.1667C13.833 18.744 15.9223 20.8333 18.4997 20.8333Z" stroke="#4F8FF7" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M34.833 32.4999V30.1666C34.8322 29.1326 34.4881 28.1282 33.8546 27.311C33.2211 26.4938 32.3342 25.9101 31.333 25.6516" stroke="#4F8FF7" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M26.666 11.6516C27.6698 11.9086 28.5596 12.4924 29.1949 13.311C29.8303 14.1295 30.1752 15.1362 30.1752 16.1724C30.1752 17.2086 29.8303 18.2154 29.1949 19.0339C28.5596 19.8525 27.6698 20.4363 26.666 20.6933" stroke="#4F8FF7" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                                                                      
                            </span>
                            <div class="p-ml-3">
                                <h1 class="p-bold display-3 p-mb-0">User Management</h1>
                            </div>
                        </div>
                    </p-card>
                </div>
                <div class="p-col-12 p-md-4 p-lg-3 dash-card cursor-pointer" routerLink="/administrationHome/roleScopeMappingList/roleScopeMapping">
                    <p-card class="box box1">
                        <div class="p-grid p-d-flex p-ai-center">
                            <span class="boxicon p-d-flex p-jc-between p-ai-center">
                                <svg class="illustrationLight" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="https://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="4" fill="#E7F2EC"/>
                                    <path d="M23.1659 33.6666H13.4437C12.8248 33.6666 12.2314 33.4208 11.7938 32.9832C11.3562 32.5456 11.1104 31.9521 11.1104 31.3333V12.6666C11.1104 12.0477 11.3562 11.4543 11.7938 11.0167C12.2314 10.5791 12.8248 10.3333 13.4437 10.3333H22.777L29.777 17.3333V24.3333" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.7773 10.3333V17.3333H29.7773" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.1107 23.1666H15.7773" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.3885 27.8333L15.7773 27.8333" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.1107 18.5H16.944H15.7773" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M29.7773 29V33.6667" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M32.111 31.3333H27.4443" stroke="#33B567" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>      
                                <svg class="illustrationDark" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="https://www.w3.org/2000/svg">
                                    <rect width="44" height="44" rx="4" fill="#333333"/>
                                    <path d="M23.1659 33.6666H13.4437C12.8248 33.6666 12.2314 33.4208 11.7938 32.9832C11.3562 32.5456 11.1104 31.9521 11.1104 31.3333V12.6666C11.1104 12.0477 11.3562 11.4543 11.7938 11.0167C12.2314 10.5791 12.8248 10.3333 13.4437 10.3333H22.777L29.777 17.3333V24.3333" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.7773 10.3333V17.3333H29.7773" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.1107 23.1666H15.7773" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.3885 27.8333L15.7773 27.8333" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.1107 18.5H16.944H15.7773" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M29.7773 29V33.6667" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M32.111 31.3333H27.4443" stroke="#70B38B" stroke-width="1.57143" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>                                      
                            </span>
                            <div class="p-ml-3">
                                <h1 class="p-bold display-3 p-mb-0">Role Scope Mapping</h1>
                            </div>
                        </div>
                    </p-card>
                </div>
            </div>
    
        </div>
    </div>
</div>
<div class="p-col-12 p-d-flex p-jc-between p-button-container p-ai-center">
    <div class="p-col-6 p-sm-6 p-md-6 p-lg-6 p-xl-6 p-text-left">
        <div class="btnwrap">
            <button pButton class="p-button-secondary p-button" (click)="backFunction()" type="button" label="Back">
            </button>
        </div>
    </div>
</div>