import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
// import { MessageService } from 'primeng/api';
import { DATA_1, DATA_2 } from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class CommonUtilsService {
    data_1: any;
    data_2: any;

    constructor() {
        this.data_1 = CryptoJS.enc.Latin1.parse(DATA_1);
        this.data_2 = CryptoJS.enc.Latin1.parse(DATA_2);
    }

    encryptWith(enc: any) {
        let slash = enc.toString();
        let encryptData = CryptoJS.AES.encrypt(slash, this.data_2, { iv: this.data_1, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
        return encryptData.toString();
    }

    decrypt(dec: any) {
        let decryptDataPlain = CryptoJS.AES.decrypt(dec, this.data_2, { iv: this.data_1, padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC });
        let decryptData = decryptDataPlain.toString(CryptoJS.enc.Utf8);
        return decryptData;
    }

    getUserId() {
        return this.decrypt(localStorage.getItem('empid'));
    }

    getUserScopes() {
        let scopesStr: string = this.decrypt(localStorage.getItem('scopes'));
        let scopes: string[] = [];
        if (scopesStr && scopesStr.length > 0) {
            scopes = scopesStr.split(',');
        }

        return scopes;
    }

    doesHaveValidScopes(requiredScopes: string[], isRequiedToHaveAllScopes: boolean = false) {
        let scopes = this.getUserScopes();

        return requiredScopes[isRequiedToHaveAllScopes ? 'every' : 'some'](requiredScope => scopes.indexOf(requiredScope) >= 0);
    }

    convertToDataURL = (file: File) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    // showToaster(severity: string, message: string) {
    //   this.messageService.add({ severity: severity, detail: message });
    //   setTimeout(() => {
    //     this.messageService.clear();
    //   }, 4000);
    // }
}
